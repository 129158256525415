import React from 'react'
import { shortAuthor } from '../utils'

export default ({ article, textColor }) => (
  <div className={`flex justify-between ${textColor}`}>
    <div className="flex">
      <div className="font-sans font-semibold pr-4">{shortAuthor(article.author.name)}</div>
      <div className="font-sans font-semibold">{article.body.childMarkdownRemark.timeToRead}m read</div>
    </div>
    <div>
      <div className="font-sans font-semibold">{article.publishDate}</div>
    </div>
  </div>
)

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import HelmetWrapper from '../components/helmet-wrapper'
import Layout from '../components/layout'
import ArticleMetadata from '../components/article-metadata'

class ArticleTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')

    return (
      <Layout location={this.props.location}>
        <div>
          <HelmetWrapper pageTitle={post.title} />
          <div>
            <div className="bg-darkBlue flex flex-col min-h-1/3 justify-end p-4">
              <div>
                <div className="md:max-w-5xl md:mx-auto">
                  <div className="font-semibold font-sans mb-2 text-lightCool text-xs tracking-wider uppercase">{post.postType}</div>
                  <div className="font-serif text-4xl mb-2 font-semibold text-theme2-accent md:text-8xl">{post.title}</div>
                  <div
                    className="font-extralight font-sans mb-4 leading-6 text-2xl text-lightCool md:text-6xl"
                    dangerouslySetInnerHTML={{
                      __html: post.description.childMarkdownRemark.html,
                    }}
                  />
                  <ArticleMetadata article={post} textColor="text-theme2-accent" />
                </div>
              </div>
            </div>
            <div
              className="article-body m-4 mb-8 font-light font-sans text-lg text-darkGray md:max-w-2xl md:mx-auto md:mt-12"
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      postType
      description {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString: "YYYY")
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      author {
        name
      }
    }
  }
`
// // Image stuff
// <div className={heroStyles.hero}>
//   <Img
//     className={heroStyles.heroImage}
//     alt={post.title}
//     fluid={post.heroImage.fluid}
//   />
// </div>
